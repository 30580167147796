<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-2"
        @click="gotoAddEvent()"
      >
        Add Event
      </b-button>
    </div>
    
    <b-row>
      <b-col cols="12" v-if="active_role == 'corporate'">
        <event-corporate></event-corporate>
      </b-col>
      <b-col cols="12" v-else-if="active_role == 'superadmin'">
        <event-superadmin></event-superadmin>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol, BButton
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import EventCorporate from './master/EventCorporate.vue'
  import EventSuperadmin from './master/EventSuperadmin.vue'

  export default {
    components: {
      BRow, BCol, BButton,
      EventCorporate, EventSuperadmin
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        active_role: null
      }
    },
		mounted() {
	    this.active_role = localStorage.getItem('role')
		},
		created() {
      this.checkAuth()
    },
    methods: {
      gotoAddEvent() {
        this.$router.push({ path: '/event/add' })
      },
    },
  }
</script>